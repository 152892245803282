import { AxiosResponse } from "axios";
import { groupBy } from "lodash";

import { errorHandling } from "utils/errorHandling";
import channelService from "../../services/channel.service";
import localize from "../../utils/localize";
import { loading, stopLoading, triggerAlert } from "../appSetting/actions";
import {
  channelOperationdData,
  channelProps,
  deleteChannelProps,
  updateChannelProps,
} from "./type";
import {
  CREATE_CHANNEL,
  CURRENT_CHANNEL,
  DELETE_CHANNEL,
  GET_CHANNELS,
  GET_ENGINES,
  SELECTED_CHANNELS,
  UPDATE_CHANNEL,
  UPDATE_TYPE_ACTION_CHANNEL,
} from "./types";

export const getMyChannels = () => async (dispatch: any) => {
  try {
    dispatch(loading());

    const response: AxiosResponse = await channelService.getMyChannels();
    // if response data channels does not exist then return empty array
    if (!response.data.channels) {
      dispatch({
        type: GET_CHANNELS,
        payload: [],
      });
      return;
    }
    let channels_groupby_engines = groupBy(
      [...(response?.data?.channels||[])],
      ({ engineID }: any) => engineID
    );

    const new_channels_groupby_engines = Object.keys(
      channels_groupby_engines
    ).map((key: string) => {
      let obj = {
        enginesType: channels_groupby_engines[key][0].engineType,
        icon: "",
        engineID: parseInt(key),
        channels: channels_groupby_engines[key],
      } as any;
      return obj;
    });
    dispatch({
      type: GET_CHANNELS,
      payload: [...new_channels_groupby_engines],
    });
  } catch (er: any) {
    errorHandling(er, dispatch, "get_channels_fail");
  } finally {
    dispatch(stopLoading());
  }
};

export const getEngines = () => async (dispatch: any) => {
  try {
    dispatch(loading());
    const response: AxiosResponse = await channelService.getEngines();
    dispatch({
      type: GET_ENGINES,
      payload: [...(response.data.engines ?? [])],
    });
  } catch (er: any) {
    errorHandling(er, dispatch, "get_engines_fail");
  } finally {
    dispatch(stopLoading());
  }
};

export const updateTypeActionChannel =
  (type: string) => async (dispatch: any) => {
    dispatch({
      type: UPDATE_TYPE_ACTION_CHANNEL,
      payload: type,
    });
  };

export const getCurrentChannel =
  (item: channelProps) => async (dispatch: any) => {
    dispatch({
      type: CURRENT_CHANNEL,
      payload: item,
    });
  };

export const updateChannel =
  (item: updateChannelProps) => async (dispatch: any) => {
    try {
      dispatch({
        type: UPDATE_CHANNEL,
        payload: item,
      });
    } catch (er: any) {
      errorHandling(er, dispatch, "update_channel_fail");
    } finally {
      dispatch(stopLoading());
    }
  };
export const updateChannelWhatsapp = (data: any) => async (dispatch: any) => {
  try {
    await channelService.updateWhatsappChannel(data);
    dispatch({
      type: UPDATE_CHANNEL,
      payload: data,
    });
  } catch (er: any) {
    errorHandling(er, dispatch, "update_channel_fail");
  } finally {
    dispatch(stopLoading());
  }
};

export const createChannel =
  (data: channelOperationdData) => async (dispatch: any) => {
    try {
      dispatch(loading());
      const response: AxiosResponse = await channelService.createChannel(data);
      dispatch({
        type: CREATE_CHANNEL,
        payload: [...(response.data.authLink ?? [])],
      });
      window.open(response.data.authLink, "_blank");
    } catch (er: any) {
      errorHandling(er, dispatch, "create_channel_fail");
    } finally {
      dispatch(stopLoading());
    }
  };

export const copyNewChannel =
  (data: channelOperationdData) => async (dispatch: any) => {
    try {
      dispatch(loading());
      const response: AxiosResponse = await channelService.createChannel(data);
      dispatch({
        type: CREATE_CHANNEL,
        payload: [...(response.data.authLink ?? [])],
      });
      return response.data.authLink;
    } catch (er: any) {
      errorHandling(er, dispatch, "create_channel_fail");
    } finally {
      dispatch(stopLoading());
    }
  };

export const createChannelWithModel =
  (engine: any) => async (dispatch: any) => {
    try {
      // this function will be used to create a channel for the following engine [Mail , whatsapp api , telegram]
      dispatch(loading());
      const response: AxiosResponse =
        await channelService.createChannelWithInformation(engine);
      if (response.status === 200) {
        dispatch(triggerAlert(localize("add_channel_success"), "success"));
      }
      /* dispatch({
        type: CREATE_CHANNEL,
        payload: [...response.data.authLink],
      }); */
    } catch (er: any) {
      errorHandling(er, dispatch, "create_channel_fail");
    } finally {
      dispatch(stopLoading());
    }
  };

export const deleteChannel =
  (data: deleteChannelProps) => async (dispatch: any) => {
    try {
      dispatch(loading());
      const response: AxiosResponse = await channelService.deleteChannel(data);
      dispatch({
        type: DELETE_CHANNEL,
        payload: { ...(response.data.channels[0] ?? {}) },
      });
      dispatch(triggerAlert(localize("delete_channel_success"), "success"));
    } catch (er) {
      errorHandling(er, dispatch, "delete_channel_fail");
    } finally {
      dispatch(stopLoading());
    }
  };

export const getSelectedChannels =
  (data: channelProps[]) => async (dispatch: any) => {
    dispatch({
      type: SELECTED_CHANNELS,
      payload: data,
    });
  };
